import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import {
  TableRow,
  TablePagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Stack, Typography, Button } from '@mui/material';
import { format } from 'date-fns';
import { ExportJsonCsv } from 'react-export-json-csv';

function UserSummaryTable({ batchData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userStatus, setUserStatus] = useState('All');

  const activeUser = batchData.users.filter((user) => {
    if (userStatus === 'All') {
      return true;
    }
    return userStatus === user.status;
  });
  const rows = activeUser.map((user) => {
    return {
      name: user.name,
      email: user.email,
      // _id: user._id,
      mentor: user.mentor2
        ? user.mentor2.name
        : user.mentor
        ? user.mentor.name
        : '',
      coach: user.coach2 ? user.coach2.name : user.coach ? user.coach.name : '',
      company: user.company2
        ? user.company2.name
        : user.company
        ? user.company.name
        : '',
      area: user.area ? user.area.title : '',
      lastLogin:
        user.user && user.user.lastLogin
          ? format(new Date(user.user.lastLogin), 'do LLL yy p')
          : '-',
      completionStatus: user.completionStatus,
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const headers = [
    {
      key: 'name',
      name: 'name',
    },
    {
      key: 'email',
      name: 'email',
    },
    {
      key: 'mentor',
      name: 'mentor',
    },
    {
      key: 'coach',
      name: 'coach',
    },
    {
      key: 'company',
      name: 'company',
    },
    {
      key: 'area',
      name: 'area',
    },
    {
      key: 'lastLogin',
      name: 'lastLogin',
    },
    {
      key: 'completionStatus',
      name: 'Certificate Status',
    },
  ];

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        {' '}
        <FormControl sx={{ marginInline: 4, width: 200 }} size='small'>
          <InputLabel id='demo-simple-select-label'>User Status</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={userStatus}
            label='userStatus'
            onChange={(e) => setUserStatus(e.target.value)}
          >
            <MenuItem value='All'>All</MenuItem>
            <MenuItem value='Active'>Active</MenuItem>
            <MenuItem value='Inactive'>Inactive</MenuItem>
          </Select>
        </FormControl>
        <ExportJsonCsv headers={headers} items={rows}>
          <Button variant='outlined'>User Details</Button>
        </ExportJsonCsv>
      </Stack>

      <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Mentor</TableCell>
              <TableCell align='left'>Coach</TableCell>
              <TableCell align='left'>Company</TableCell>
              <TableCell align='left'>Area</TableCell>
              <TableCell align='center'> Last login</TableCell>
              <TableCell align='center'> Certificate Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.email}>
                  <TableCell component='th' scope='row' padding='none'>
                    <Stack direction='row' alignItems='left' spacing={2}>
                      <Typography variant='subtitle2' noWrap>
                        {row.name}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align='left'>{row.email}</TableCell>
                  <TableCell align='left'>{row.mentor}</TableCell>
                  <TableCell align='left'>{row.coach}</TableCell>
                  <TableCell align='left'>{row.company}</TableCell>
                  <TableCell align='left'>{row.area}</TableCell>
                  <TableCell align='center'>{row.lastLogin}</TableCell>
                  <TableCell align='center'>
                    {row.completionStatus === 'Not Applicable'
                      ? '-'
                      : row.completionStatus}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default UserSummaryTable;
